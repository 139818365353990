<template>
  <v-container fluid>
    <template>
      <v-tabs v-model="currentTab" @change="switchTab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <v-card class="my-4" flat>
          <component
            v-if="isServicePointExist && isComponentRerendered"
            :is="item.component"
            :currentItem="editedItem"
            :isDataSaved="isDataSaved"
            :currentTabName="currentTabName"
            :editCard="editCard"
            @saveData="emitSaveData"
            @changes="changes"
          ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ForceRerender from '@/mixins/forceRerender'
import cloneDeep from '@/mixins/cloneDeep'

export default {
  name: 'Point',

  components: {
    Info: () => import('@/components/administration/service_point/point/Info'),
    CardContacts: () => import('@/components/partials/contacts/CardContacts'),
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['changes', 'showEditButtons'],
  mixins: [ForceRerender, cloneDeep],

  data: () => {
    return {
      editedItem: null,
      currentTab: 0,
      tabs: [
        {
          name: 'info',
          title: 'Інфо',
          component: 'Info',
        },
        {
          name: 'contacts',
          title: 'Контакти',
          component: 'CardContacts',
        },
      ],
    }
  },

  created() {
    this.initialize()
  },

  computed: {
    isServicePointExist() {
      return !!Object.entries(this.currentItem).length
    },
    currentTabName() {
      return this.tabs[this.currentTab].name
    },
  },

  methods: {
    initialize() {
      this.editedItem = this.cloneObjectDeep(this.currentItem)
    },
    emitSaveData(data) {
      this.$emit('saveData', data)
      this.editedItem = this.cloneObjectDeep(data)
    },
    switchTab(tab) {
      this.$emit('showEditButtons', tab === 0)
    },
    changes() {
      this.$emit('changes')
      if (this.currentTabName === 'contacts') {
        this.$emit('showEditButtons', true)
      }
    },
  },
}
</script>
